function datetimeToStringDate(datetime) {
  const date = datetime.split("T")[0];
  const [a, m, d] = date.split("-");
  return d + "/" + m + "/" + a;
}

function datetimeToString(datetime) {
  const [date, time] = datetime.split("T");
  const [a, m, d] = date.split("-");
  const [h, min] = time.split(".")[0].split(":");
  return d + "/" + m + "/" + a + " " + h + ":" + min;
}

function dateJsToJSON(d) {
  const date = d.toJSON().split("T")[0];
  const time = d.toLocaleTimeString() + ".000";
  return date + "T" + time;
}

function dateTimeNow() {
  var today = new Date();
  var date =
    today.getFullYear() +
    "-" +
    formatMinDigits(today.getMonth() + 1, 2) +
    "-" +
    formatMinDigits(today.getDate(), 2);
  var time =
    String(today.getHours()).padStart(2, "0") +
    ":" +
    String(today.getMinutes() - 2).padStart(2, "0") +
    ":" +
    String(today.getSeconds()).padStart(2, "0");
  return date + "T" + time;
}

function formatMinDigits(s, n) {
  let output = `${s}`;
  let initialLength = output.length;
  if (n && initialLength < n) {
    for (let i = 0; i < n - initialLength; i++) {
      output = "0" + output;
    }
  }
  return output;
}

function isSecondDateBeforeFirst(date1, date2) {
  if (!date1 || !date2) return true;
  if (typeof date1 === "string") {
    date1 = new Date(date1);
  }
  if (typeof date2 === "string") {
    date2 = new Date(date2);
  }
  if (date2 < date1) {
    return true;
  } else {
    return false;
  }
}

function adjustDateInInterval(date, interval) {
  // Ensure the date is a Date object
  if (typeof date === "string") {
    date = date + " 12:00:000";
    date = new Date(date);
  }

  // Ensure the interval extremes are Date objects
  let [start, end] = interval.map((i) =>
    typeof i === "string" ? new Date(i) : i
  );

  // Check if the date is within the interval
  if (date >= start && date <= end) {
    date;
    return date;
  }

  // If the date is before the interval, return the lower extreme
  if (date < start) {
    start;
    return start;
  }

  // If the date is after the interval, return the higher extreme
  if (date > end) {
    end;
    return end;
  }
}

export {
  datetimeToStringDate,
  datetimeToString,
  dateJsToJSON,
  dateTimeNow,
  isSecondDateBeforeFirst,
  adjustDateInInterval,
};
